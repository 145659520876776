import React from "react"
import { graphql, PageProps } from "gatsby"

import getSearchParam from "@ecom/ui/utils/getSearchParam"

import Layout from "../components/Layouts/mainPage"
import { HeaderAuraCard } from "../components/Headers/HeaderAuracard"
import { Banner } from "../components/Banners/HalvaCardVariation"
import { CardWithBenefits } from "../components/CardWithBenefits"
import { Category } from "../components/Category"
import { PlanetaForm } from "../components/NewForms/PlanetaForm"
import { NewFooter } from "../components/NewFooter"
import type { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"

const FORM_TITLE_PERM =
  "Заполните форму и получите карту курьером или у сотрудника Совкомбанка в ТРЦ «Планета»"

const FORM_TITLE =
  "Заполните форму и получите карту курьером или на стойке Совкомбанка в ТРЦ «Планета»"

const BENEFITS_TITLE = (
  <>
    Преимущества <br /> «ХАЛВА PLANETACARD»
  </>
)

const titleBanner = (
  <>
    <span style={{ color: "rgba(255, 78, 80, 1)" }}>ХАЛВА PLANETACARD —</span> одна карта для всего
  </>
)

const descBanner = (
  <>
    Уникальные условия по Халве <br />
    на покупки в ТРЦ «Планета»
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { title, description, ligal, noIndex } = getPageData(data)
  const isPerm = getSearchParam("utm_campaign") === "gethalva-perm"

  return (
    <Layout description={description} title={title} noHeader noFooter noIndex={noIndex}>
      <HeaderAuraCard />
      <Banner
        variantImg="planeta"
        variantStyle="planeta"
        title={titleBanner}
        description={descBanner}
        orderNum="1"
      />
      <CardWithBenefits tc="planet" title={BENEFITS_TITLE} orderNum="2" />
      <Category orderNum="3" />
      <PlanetaForm title={isPerm ? FORM_TITLE_PERM : FORM_TITLE} orderNum="4" />
      <NewFooter ligal={ligal} isRedButton orderNum="5" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halva-planetacard/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
